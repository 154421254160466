import React, { useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateTranslate } from '../action/translate.action'
import { CODE_ENG, Translate } from '../config'

function PolicyEngPage() {
  const spas = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateTranslate(CODE_ENG))
    localStorage.setItem(Translate, CODE_ENG)
  }, [dispatch])
  return (
    <Container>
      <h1>Privacy and Personal Data Protection Policy </h1>
      <p>
        {spas}SKY ICT PUBLIC COMPANY LIMITED and its subsidiary{' '}
        <span className="fw-bold">“(Company)”</span>, ORBIT Application, a
        service provider hereby intends to invite you to understand a Private
        policy because this policy explains the company’s measures to your
        personal data such as data collection, storage, usage, and disclosure
        including other of your rights, etc. in order that you recognize the
        private policy of the company’s personal data. The Company recognizes
        the importance of the protection of the personal data of the employee
        including the company’s partner. For all this reason, the Company
        provides the measure of storage and standard protection of law, rules,
        and regulations related to personal data protection strictly as follows:
      </p>
      <h5>1. Definition</h5>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“Personal data”</span> refers any information
        relating to a person which enable the identification of such person
        whether directly or indirectly but not including the specific
        information of deceased persons.
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“Data controller”</span> refers individual or
        juristic person who have the power to make a decision related to data
        collection, usage or disclosure of such personal data.
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“Data Processor”</span> refers individual or
        juristic person operating related to collection, usage, or disclosure of
        personal data according to the order or on behalf of data controller. By
        the way, the individual or juristic person operating such action shall
        not be the data controller.
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“Data Subject”</span> refers customers,
        employees, partners who is the individual. “Individual” refers
        individual. While this policy covers the personnel such as directors
        (internal), managements (internal), employees, outsource employees,
        employees successfully pass the probation, newcomers, job applicants,
        which this policy will refers to.
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“Data Processing”</span> refers any
        transactions by the Company the personal data of personnel including
        data collection, usage, storage, disclosure, and erasure.
      </p>
      <p className="">
        {spas}
        <span className="fw-bold">“Data Processing Basis”</span> refers any
        necessity reason using for data processing of personal data according to
        Section 24 and Section 26 of Personal Data Protection Act B.E. 2562
        (2019).
      </p>

      <h5>2. Scope</h5>
      <p className="mb-0">
        {spas} - This policy set the principle framework and guidelines of
        importance operations for personal data protection of the Company to
        operate work according to the duties and responsibilities of the Company
        in the capacity as the data controller according to the Personal Data
        Protection Act B.E. 2562 (2019).
      </p>
      <p className="mb-0">
        {spas} - This policy shall enforce the personal data processing result
        according to the scope and objectives of the Company including the
        personal data processing operated by the individual or external juristic
        person or by the equipment or external system according to such scope
        and objectives.
      </p>
      <p className="mb-0">
        {spas} - This policy shall not cover the personal data processing of the
        personnel of the Company that operates for personal benefits or for
        family benefits only, excluding the Company’s scope and objectives.
      </p>
      <p className="">
        {spas} - To comply with the policy shall also comply with the guideline
        or policy as well as discussion issued as appeared in the details
        according to this policy, including announcement, or other regulations
        related to information technology management, details, guidelines, and
        all meanings.
      </p>
      <h5>3. Objectives</h5>
      <p className="mb-0">
        {spas}This personal data protection policy is for personnel of SKY ICT
        PUBLIC COMPANY LIMITED by having SKY ICT PUBLIC COMPANY LIMITED and its
        subsidiary (“Company”) as the data controller according to the Personal
        Data Protection Act B.E. 2562 (2019) which can be prescribed the Company
        has collected and used the personal data of the service users according
        to the scope and objectives of the company.
      </p>
      <p>
        {spas}However, in certain cases, the Company may perform the duty as the
        data processor for the project's juristic person which is the external
        agencies performing as the data controller. In this case, the Company
        will collect and use the personal data of the personnel within the
        prescribed scope and objectives pursuant to the project juristic person.
      </p>

      <h5>4. Collection of limited personal data </h5>
      <p className="mb-0">
        {spas}The Company shall collect personal data within the purpose, scope,
        a lawful and fair method which is defined in the scope of the
        collection, storage including data collection and storage limited only
        as is necessary to the service or other type of electronics service
        which is defined in the scope of the Company’s objectives only. By the
        way, the company shall notify the data subject to acknowledge, explicit
        consent via electronics as defined by the type of the Company’s method.
      </p>
      <p className="mb-0">
        {spas} The Company shall collect the personal data from personnel
        through direct procedures such as
      </p>
      <p className="mb-0">
        {spas}- Filling out the form, documents, job application form, or record
        book.
      </p>
      <p className="mb-0">
        {spas}- Filling out the information via Company’s website or the data
        subject sent their information directly to the Company.
      </p>
      <p className="mb-0">
        {spas}- 3 third-Party Platforms such as Facebook, Google Drive, etc.
      </p>
      <p>
        {spas}In case you are not consented to provide the personal data to the
        Company, the Company may be unable to conduct the recruitment and
        employment as per your request. Furthermore, the Company may unable to
        produce the employment agreement and perform according to the employment
        agreement that may already provide to you including compensation
        management/benefits management/ salary, wages, benefits management/
        training and development/ scholarship granting/ work performance
        evaluation or unable to comply lawfully with the obligations under the
        Company such as announcement or regulations related to personnel
        administration that issued according to the Labor Protection Act,
        taxation management including compliance to the court order.
      </p>
      <h5>
        5. What information that the Company can conduct the data processing?
      </h5>
      <p className="mb-0">
        {' '}
        {spas}- Identification data such as name-surname, personal photo etc.
      </p>
      <p className="mb-0">
        {spas}- Address and contactable address data such as address, mobile
        phone number, email, work address etc.
      </p>
      <p className="mb-0">
        {spas}- Personal data such as nationality, date of birth, age, special
        interest, perspective and opinion etc.
      </p>
      <p className="mb-0">
        {spas}- ID data such as employee ID, Identification number, signature
        etc.
      </p>
      <p className="mb-0">
        {spas}- IT data such as GPS Location, User ID, Time in & out,
        transaction date etc.
      </p>
      <p className="mb-0">
        {spas}- Financial data such as bank account number, salary etc.
      </p>
      <p className="mb-0">
        {spas}- Working data such as position, section/department, leave of
        absence reason etc.
      </p>
      <p className="mb-0">
        {spas}- Documentary evidence such as copy of identification card, copy
        of passport etc.
      </p>
      <p className="mb-0">
        {spas}In addition, the Company may conduct the data processing of
        special individual who has the sensitive on each case such as:
      </p>
      <p className="mb-0">
        {' '}
        {spas}- Health data such as physical condition, medical certificate etc.
      </p>
      <p className="mb-0">
        {' '}
        {spas}- Biometric data such as Biometric: Face, Fingerprint etc.
      </p>
      <p> {spas}- Criminal record data</p>
      <h5>6. Objectives of data collection</h5>
      <p className="">
        {spas}
        The Company may use the personal data to conduct according to the
        Company’s objectives related to the personnel as per data processing
        basis following the Personal Data Protection Act B.E. 2562 (2019) which
        can be summarized as follows:
      </p>
      <Table bordered responsive>
        <thead>
          <tr>
            <th className="tr-header">Activities Group</th>
            <th className="tr-header">Personal Data Group</th>
            <th className="tr-header">Data Processing Basis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                <span className="fw-bold">Recruitment Process</span>{' '}
                (Recruitment Process included to direct data via filling out the
                information via Company’s website, job application form or the
                information that you have been directly sent to the Company){' '}
                <span className="fw-bold">(SPD 4-11)</span>
              </p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Consent basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">
              New Employee (New Employee process SPD12-16)
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Training (Training SPD 20-22)</td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>- Agreement basis</td>
          </tr>
          <tr>
            <td className="fw-bold">
              Organization Communication (Organization Communication SPD 23-24)
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Personal data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Consent basis</p>
              <p className="mb-0">-Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">COVID-19 Survey (COVID-19 Survey)&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Health data</p>
              <p className="mb-0">- ATK record data&nbsp;</p>
            </td>
            <td>- Lawful responsibility basis</td>
          </tr>
          <tr>
            <td className="fw-bold">Internal Document (Internal Document)</td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Request System (Request System)</td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>

          <tr>
            <td className="fw-bold">Welfare (Employee welfare)</td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful benefits basis</p>
              <p className="mb-0">- Lawful responsibility basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Payroll (Payroll)</p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Entry Record (Entry Record SPD37)&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Biometric data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Consent basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Finance (Financial transaction)</p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">
                Support APPS Project (Support APPS Project)
              </p>
            </td>
            <td>
              <p className="mb-0">- Name - Surname</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- IT data&nbsp;</p>
            </td>
            <td>- Agreement basis</td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Verification (Verification)</p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful Responsibility basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Data Report (Data Report)</td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful responsibility basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Collect Data (Data Collection)</p>
            </td>
            <td>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- Documentary evidence&nbsp;</p>
            </td>
            <td>- Lawful responsibility basis</td>
          </tr>
          <tr>
            <td className="fw-bold">Tracking Location (Tracking Location)</td>
            <td>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Address and contactable address data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- Working data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Agreement basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Referral Program (SPD3)</td>
            <td>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Financial data</p>
              <p className="mb-0">- Personal data</p>
              <p className="mb-0">- Working data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Lawful benefits basis </p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Entry-Exit of Location (SPD39)</td>
            <td>
              <p className="mb-0">- Identification data</p>
              <p className="mb-0">- Working data</p>
              <p className="mb-0">- ID data</p>
              <p className="mb-0">- IT data</p>
              <p className="mb-0">- Biometric data&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- Consent basis</p>
              <p className="mb-0">- Lawful benefits basis&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </Table>
      <p className="mb-0">
        {spas}
        The Company shall conduct the data processing following the objectives
        prescribed only. In certain cases it may consider whether your data can
        be processed for other related reasons or not and whether it is against
        or above former objectives or not, while in case the Company may
        necessary to conduct data processing by other objectives that do not
        relate to the former objectives, the Company shall request a new consent
        for the usage of data in under the objectives that requesting as per the
        request following the process again.
      </p>
      <p className="mb-0">
        {spas}The Company may be essential to send the data to external agencies
        as following to conduct data processing according to the
        responsibilities as per agreement or law such as :
      </p>
      <p className="mb-0">{spas}- Medical Facility&nbsp;&nbsp;&nbsp;</p>
      <p className="mb-0">{spas}- Related Emergency Agencies</p>
      <p className="mb-0">{spas}- Bank&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p className="mb-0">{spas}- Subsidiary Company&nbsp;</p>
      <p>
        {spas}In case of usage or sending the personal data to external
        agencies, the Company shall perform as is necessary by using or sending
        the information fewest and may consider preparing Data Anonymization,
        Pseudonymous Data to secure the data. In case the external person is the
        data processor for the Company, it shall prepare the personal data
        protection measure as appropriate following this policy, and the Company
        may not authorize such external parties to use the data for other
        objectives as prescribed by the Company.
      </p>
      <h5>7. Right of data subject</h5>
      <p className="mb-0">{spas}7.1 Right to be informed</p>
      <p className="mb-0"> {spas}7.2 Right of Access&nbsp;</p>
      <p className="mb-0"> {spas}7.3 Right to Data Portability</p>
      <p className="mb-0">{spas}7.4 Right to Object</p>
      <p className="mb-0"> {spas}7.5 Right to erasure</p>
      <p className="mb-0">{spas}7.6 Right to Restrict Processing</p>
      <p className="mb-0"> {spas}7.7 Right to Rectification</p>
      <p className="mb-0">
        {spas}In some cases, as per the condition of operation, the Company may
        be unable to perform following your requests such as having the
        necessity to perform as per the responsibility, agreement, or law, etc.
        However, in case you provide consent to the data processing, you can
        withdraw such consent at all times by contacting the related agencies.
        The Company shall terminate such data processing as soon as possible,
        but such consent withdrawal shall not result in the cancellation of such
        withdrawal of data processing that has been already performed.
      </p>
      <p>
        {spas}Please acknowledge that the Company will record all transactions
        that have been operated to your request to be used for solving all
        problems that may occur.
      </p>
      <h5>8. Consent Withdrawal</h5>
      <p className="mb-0">
        {spas}
        The data subject shall be able to withdraw consent provided to the
        Company for collection, usage, or disclosure of such individual at all
        times by notifying the intention of the Company to be acknowledged with
        the reason for such action. The Company may operate following such
        notification unless in case that there is a restriction to withdraw such
        consent by law or agreement that benefits the data subject.
      </p>
      <p>
        {spas}By the way, withdrawal of consent provided by the data subject
        shall not result in the data collection, usage, or disclosure that the
        data subject has been provided to the Company.
      </p>
      <h5>9. Refusal and Record Preparation</h5>
      <p className="mb-0">
        {spas}In case the data subject requests the Company to operate as
        specified in clauses 7.1, 7.8, or 8., the Company shall perform as per
        the request within the suitable time and following the process of the
        Company unless in the following case, the Company may refuse to the
        following operation.
      </p>
      <p className="mb-0">
        {' '}
        {spas}9.1 By the condition it may be unable to such operation.
      </p>
      <p className="mb-0">
        {spas}9.2 By the refusal as per the law of court’s order and access and
        request for a copy of personal data that may result to the right and
        other freedom.
      </p>
      <p className="mb-0">
        {spas}9.3 Sending or transferring personal data which is a duty
        performed for society's benefits or performing duties as per law or
        exercising of rights that may violate other rights or freedom.
      </p>
      <p className="mb-0">
        {spas}9.4 Collection, usage, or disclosure of personal data of such
        individual, the data control has represented the lawful reason that is
        of significant importance.
      </p>
      <p>
        {spas}9.5. Collection, usage, or disclosure of personal data of such
        individual which is for claiming the rights under the compliance law or
        claiming right as per law or fight against the law to claim for the
        rights in case that the Company refuses to operate as per the data
        subject’s request to perform following any of clause as follows: 7.1,
        7.8 or 8, the Company shall prepare the refusal report with the reason
        of refusal cause and transferring to maintain at the section, a
        department within the Company who refused of the request of data subject
        request.
      </p>
      <h5>10. Data Security and Quality Measure</h5>
      <p>
        {spas}The Company recognizes the importance of the security and safety
        of your data, the Company therefore, sets the measure of preserving the
        security of your data appropriately and corresponding to store your
        information confidential to prevent the loss, access, damage, usage,
        alteration, rectification, or disclosure of your data without any rights
        or illegally including preventing to allow to use your data to be used
        by unauthorized. Moreover, the Committee of the Company has prepared the
        policy, regulations, manual, guidelines, and training to store, use, and
        disclose personal data so that all employees at all levels to store, use
        and disclose their data while complying with the Company’s standard and
        corresponded to the applicable laws.
      </p>
      <h5>11. Data Protection Officer</h5>
      <p className="mb-0">
        {spas}The Company complies with the Personal Data Protection B.E. 2562
        (2019) by appointing the Data Protection Officer (DPO) to examine the
        Company’s operation related to the collection, usage, and disclosure of
        personal data corresponding to the Personal Data Protection B.E. 2562
        (2019) including applicable law related to Personal data protection.
        Furthermore, the Company prepares the regulations, and orders to the
        relevant persons complying with the prescribed rules so that the
        operation shall comply with the policy of the personal data protection
        to be in order including it is to correspond to the policy of Personal
        Data Protection Committee and Cyber Security prescribed by the Company.
      </p>
      <h5>12. Storage Period and Data Destruction</h5>
      <p>
        {spas}The Company will store the data as notified by the data subject if
        the necessary period is for operating following to abovementioned
        objectives unless in case of there is a law prescribed in particular to
        store it longer or as long as necessary for the right establishment
        following the law. The compliance or exercising of right claiming or
        fight against of right as per law or for conduction following to the
        agreement that the Company has the right hereto as per law to store the
        personal information, the Company prepares for Sky ICT Data Retention
        Policy to specify the data retention period.
      </p>
      <h5>13. Communication Channel with the Company</h5>
      <Table bordered responsive>
        <thead>
          <tr>
            <th className="tr-header">
              Details of Data Controller /Data Processor
            </th>
            <th className="tr-header">Details of Data Protection Officer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className="mb-0">
                SKY ICT PUBLIC COMPANY LIMITED and its subsidiary
              </p>
              <p className="mb-0">
                No. 55, A.A Capital Ratchada Building, 5th-7th Floor
                Ratchadapisek Road, Din Daeng Sub-district,
              </p>
              <p className="mb-0">
                Din Daeng District, Bangkok Metropolis, 10400
              </p>
              {/* <p className="mb-0">Tel: 0 2029 7888</p> */}
              <p className="mb-0">E-mail: info@skyict.co.th</p>
            </td>
            <td>
              <p>E-mail: DPO@skyict.co.th</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default PolicyEngPage
