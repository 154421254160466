import React, { useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateTranslate } from '../action/translate.action'
import { CODE_TH, Translate } from '../config'
function PolicyTHPage() {
  const spas = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateTranslate(CODE_TH))
    localStorage.setItem(Translate, CODE_TH)
  }, [dispatch])

  return (
    <Container>
      <h1>นโยบายความเป็นส่วนตัว และ คุ้มครองข้อมูลส่วนบุคคล </h1>
      <p>
        {spas}บริษัท สกาย ไอซีที จำกัด (มหาชน). และบริษัทในเครือ{' '}
        <span className="fw-bold">“(บริษัท)”</span> ผู้ให้บริการ ORBIT
        Application ขอแนะนำให้ท่านทำความเข้าใจนโยบายส่วนบุคคล (Privacy policy)
        นี้
        เนื่องจากนโยบายนี้อธิบายถึงวิธีการที่บริษัทปฏิบัติต่อข้อมูลส่วนบุคคลของท่าน
        เช่น การเก็บรวบรวม การจัดเก็บรักษา การใช้ การเปิดเผย รวมถึงสิทธิ์ต่าง ๆ
        ของท่าน เป็นต้น
        เพื่อให้ท่านได้รับทราบถึงนโยบายในการคุ้มครองข้อมูลส่วนบุคคลของบริษัท
        บริษัท ตระหนักถึงความสำคัญของการปกป้องข้อมูลส่วนบุคคลของพนักงาน
        รวมตลอดถึงคู่ค้าของบริษัท
        ด้วยเหตุนี้บริษัทจึงได้จัดให้มีมาตรการในการเก็บรักษา
        และป้องกันตามมาตราฐานของกฎหมาย ข้อกำหนด
        และระเบียบเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล อย่างเคร่งครัด ดังต่อไปนี้{' '}
      </p>
      <h5>๑.คำนิยาม</h5>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“ข้อมูลส่วนบุคคล”</span> หมายความว่า
        ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
        แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“ผู้ควบคุมข้อมูลส่วนบุคคล”</span> หมายความว่า
        บุคคลหรือนิติบุคคลซึ่งทำให้มีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“ผู้ประมวลผลข้อมูลส่วนบุคคล”</span>{' '}
        หมายความว่า บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล
        ทั้งนี้
        บุคคลหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“เจ้าของข้อมูล”</span> หมายความว่า ลูกค้า
        พนักงาน คู่ค้า ซึ่งเป็นบุคคลธรรมดา “บุคคล” หมายความว่า บุคคลธรรมดา
      </p>
      <p className="mb-0">
        {spas} นโยบายนี้ครอบคลุม บุคลากร ได้แก่ กรรมการบริษัท (ภายใน), ผู้บริหาร
        (ภายใน), พนักงาน, พนักงาน Outsource, พนักงานผ่านทดลองงาน, พนักงานใหม่,
        ผู้สมัครงาน ตามนโยบายนี้จะใช้คำว่า
      </p>
      <p className="mb-0">
        {spas}
        <span className="fw-bold">“การประมวลผล”</span> หมายถึง การดำเนินการใด ๆ
        ของบริษัท ต่อข้อมูลส่วนบุคคลของ บุคลากร รวมถึง การเก็บรวบรวม, การใช้,
        การจัดเก็บ, การเปิดเผย และการลบข้อมูลส่วนบุคคล
      </p>
      <p>
        {spas}
        <span className="fw-bold">“การประมวลผล”</span>“การประมวลผล” หมายถึง
        เหตุผลความจำเป็นในการประมวลผลข้อมูลส่วนบุคคลตามมาตรา ๒๔ และ มาตรา ๒๖
        แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
      </p>
      <h5>๒. ขอบเขต</h5>
      <p className="mb-0">
        {spas} -
        นโยบายนี้วางกรอบหลักการและแนวทางในการดำเนินการที่สำคัญสำหรับการคุ้มครองข้อมูลส่วนบุคคลของบริษัท
        เพื่อให้การดำเนินงานทั้งปวงเป็นไปตามหน้าที่และความรับผิดชอบของบริษัทในฐานะที่เป็นผู้ควบคุมข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        พ.ศ. ๒๕๖๒
      </p>
      <p className="mb-0">
        {spas} -
        นโยบายนี้ใช้บังคับกับการประมวลผลข้อมูลส่วนบุคคลทั้งปวงที่เกิดขึ้นตามขอบเขตและวัตถุประสงค์ของบริษัท
        ซึ่งรวมถึงการประมวลผลข้อมูลส่วนบุคคลที่ดำเนินการโดยบุคคลหรือนิติบุคคลภายนอก
        หรือโดยอุปกรณ์หรือระบบภายนอกตามขอบเขตและวัตถุประสงค์ดังกล่าวด้วย
      </p>
      <p className="mb-0">
        {spas} -
        นโยบายนี้ไม่ครอบคลุมถึงการประมวลผลข้อมูลส่วนบุคคลของบุคลากรของบริษัทที่ดำเนินการเพื่อประโยชน์ส่วนตนหรือเพื่อกิจกรรมในครอบครัวของบุคคลนั้นเท่านั้น
        อันไม่เกี่ยวข้องกับขอบเขตและวัตถุประสงค์ของบริษัท
      </p>
      <p>
        {spas} -
        การปฏิบัติตามนโยบายนี้จะต้องดำเนินการตามแนวปฏิบัติหรือนโยบายหรือข้อหารือที่จะได้ออกในรายละเอียดต่อไปตามนโยบายนี้
        รวมถึง ประกาศ
        หรือระเบียบอื่นของบริษัทที่เกี่ยวข้องกับการบริหารจัดการข้อมูลสารสนเทศ
        รายละเอียด แนวปฏิบัติ และความหมายต่าง ๆ
      </p>
      <h5>๓. วัตถุประสงค์</h5>
      <p className="mb-0">
        {spas}นโยบายคุ้มครองข้อมูลส่วนบุคคลสำหรับบุคลากร ของบริษัท สกาย ไอซีที
        จํากัด (มหาชน) โดยมี บริษัท สกาย ไอซีที จํากัด (มหาชน) และบริษัทในเครือ
        (“บริษัท”)
        เป็นผู้ควบคุมข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
        ๒๕๖๒ ซึ่งจะได้อธิบายว่า บริษัท ได้เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของ
        ผู้ใช้บริการ ตามขอบเขตและวัตถุประสงค์ของ บริษัท
      </p>
      <p>
        {spas}อย่างไรก็ตามในบางกรณี บริษัท
        อาจทำหน้าที่เป็นผู้ประมวลผลข้อมูลส่วนบุคคลให้กับนิติบุคคลโครงการต่าง ๆ
        ซึ่งเป็นหน่วยงานภายนอกที่เป็นผู้ควบคุมข้อมูล โดยในกรณีนี้ บริษัท
        จะเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของบุคลากรตามขอบเขตและวัตถุประสงค์ของนิติบุคคลโครงการต่าง ๆ
      </p>
      <h5>๔. การเก็บข้อมูลส่วนบุคคลอย่างจำกัด</h5>
      <p className="mb-0">
        {spas}การจัดเก็บรวบรวมข้อมูลส่วนบุคคลจะกระทำโดยมี วัตถุประสงค์ ขอบเขต
        และใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรม ในการเก็บรวบรวมและจัดเก็บข้อมูล
        ตลอดจนเก็บรวบรวม
        และจัดเก็บข้อมูลส่วนบุคคลอย่างจำกัดเพียงเท่าที่จำเป็นแก่การให้บริการ
        หรือบริการด้วยวิธีการทางอิเล็กทรอนิกส์อื่นใดภายใต้วัตถุประสงค์ของบริษัทเท่านั้น
        ทั้งนี้บริษัทจะดำเนินการให้เจ้าของข้อมูล รับรู้ ให้ความยินยอม
        ทางอิเล็กทรอนิกส์ ตามแบบวิธีการของบริษัท
      </p>
      <p className="mb-0">
        {spas} บริษัท เก็บรวบรวมข้อมูลส่วนบุคคลจาก บุคลากร โดยตรงผ่านกระบวนการ
        เช่น
      </p>
      <p className="mb-0">
        {spas}- กรอกลงในแบบฟอร์ม เอกสาร ใบสมัครงาน หรือสมุดบันทึก
      </p>
      <p className="mb-0">
        {spas}- กรอกข้อมูลในเว็บไซต์บริษัท
        หรือการที่ท่านส่งข้อมูลส่วนบุคคลของท่านมาที่บริษัทโดยตรง
      </p>
      <p className="mb-0">
        {' '}
        {spas}- 3 third-Party Platforms เช่น Facebook, Google Drive เป็นต้น
      </p>
      <p>
        {spas}ทั้งนี้ หากท่านไม่ให้ข้อมูลส่วนบุคคลของคุณแก่บริษัท
        บริษัทอาจไม่สามารถดำเนินการรับสมัครและจ้างแรงงานให้แก่ท่านตามคำขออีกทั้ง
        บริษัท
        อาจไม่สามารถประกอบการทำสัญญาจ้างและการดำเนินการที่เกี่ยวข้องกับสัญญาจ้างงานที่บริษัทอาจเคยทำไว้กับท่าน
        รวมถึง การบริหารค่าตอบแทน/ การบริหารสิทธิ์ประโยชน์ / การจ่ายเงินเดือน
        ค่าจ้าง และสิทธิ์ประโยชน์ / การฝึกอบรมและพัฒนา / การให้ทุนการศึกษา /
        การประเมินผลงาน หรือไม่สามารถปฏิบัติภาระผูกพันของบริษัท ตามกฎหมายได้
        เช่น
        ประกาศหรือข้อบังคับเกี่ยวกับการบริหารงานบุคคลที่ออกตามความพระราชบัญญัติคุ้มครองแรงงาน
        การบริหารจัดการด้านภาษีอากรของ รวมถึงการปฏิบัติตามคำสั่งศาล
      </p>
      <h5>๕. บริษัท ประมวลผลข้อมูลส่วนบุคคลใดบ้าง</h5>
      <p className="mb-0">
        {' '}
        {spas}- ข้อมูลอัตลักษณ์ เช่น ชื่อ-นามสกุล, รูปภาพบุคคล เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูลที่อยู่และที่ติดต่อ เช่น ที่อยู่, หมายเลขโทรศัพท์มือถือ,
        อีเมล, สถานที่ทำงาน เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูลประวัติ เช่น สัญชาติ, วันเดือนปีเกิด, อายุ,
        ความสนใจส่วนตัว, มุมมองและความคิดเห็น เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูล ID เช่น รหัสประจำตัวพนักงาน, เลขประจำตัวประชาชน,
        ลายมือชื่อ เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูล IT เช่น GPS Location, User ID, Time in &amp; out,
        เวลาทำรายการ เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูลเกี่ยวกับการเงิน เช่น ข้อมูลเลขที่บัญชีธนาคาร, เงินเดือน
        เป็นต้น
      </p>
      <p className="mb-0">
        {spas}- ข้อมูลเกี่ยวกับการทำงาน เช่น ตำแหน่งงาน, สายงาน/แผนก,
        เหตุผลในการลา เป็นต้น
      </p>
      <p className="mb-0">
        {' '}
        {spas}- เอกสารหลักฐาน เช่น สำเนาบัตรประชาชน, สำเนาหนังสือเดินทาง เป็นต้น
      </p>
      <p className="mb-0">
        {spas}นอกจากนี้บริษัท
        อาจประมวลผลข้อมูลส่วนบุคคลประเภทพิเศษที่มีความอ่อนไหวในบางกรณี เช่น
      </p>
      <p className="mb-0">
        {' '}
        {spas}- ข้อมูลสุขภาพ เช่น สภาพร่างกาย, ใบรับรองแพทย์ เป็นต้น
      </p>
      <p className="mb-0">
        {' '}
        {spas}- ข้อมูลชีวมิติ เช่น Biometric: Face, Fingerprint เป็นต้น
      </p>
      <p> {spas}- ข้อมูลประวัติอาชญากรรม&nbsp;</p>
      <h5>๖. วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</h5>
      <p>
        {spas}
        บริษัทใช้ข้อมูลส่วนบุคคลเพื่อการดำเนินการทั้งหลายตามวัตถุประสงค์ของบริษัท
        ที่เกี่ยวข้องกับ บุคลากร
        ตามฐานการประมวลผลข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        พ.ศ. ๒๕๖๒ ซึ่งสรุปได้ดังนี้
      </p>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th className="tr-header">กลุ่มกิจกรรม</th>
            <th className="tr-header">กลุ่มข้อมูลส่วนบุคคล</th>
            <th className="tr-header">ฐานการประมวผล</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                {' '}
                <span className="fw-bold">Recruitment Process</span>{' '}
                (กระบวนการรับสมัครพนักงาน
                ซึ่งรวมถึงข้อมูลที่เก็บโดยตรงผ่านการกรอกข้อมูลในเว็บไซต์บริษัท
                การกรอกใบสมัครงาน
                หรือการที่ท่านส่งข้อมูลส่วนบุคคลของท่านมาที่บริษัทโดยตรง){' '}
                <span className="fw-bold">(SPD 4-11)</span>
              </p>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานความยินยอม</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <span className="fw-bold">
                New Employee (กระบวนการรับพนักงานใหม่ SPD12-16)
              </span>{' '}
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <span className="fw-bold">Training (การอบรม SPD 20-22)</span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT&nbsp;</p>
            </td>
            <td>- ฐานสัญญา</td>
          </tr>
          <tr>
            <td>
              <span className="fw-bold">
                Organization Communication (การสื่อสารองค์กร SPD23-24)
              </span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานความยินยอม</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              <span className="fw-bold">
                COVID-19 Survey (การทำแบบสำรวจคัดกรองโรคโควิด 19)
              </span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูล IT</p>
              <p className="mb-0">- ข้อมูลสุขภาพ</p>
              <p className="mb-0">- ข้อมูลการตรวจ ATK&nbsp;</p>
            </td>
            <td>- ฐานหน้าที่ตามกฎหมาย</td>
          </tr>
          <tr>
            <td>
              {' '}
              <span className="fw-bold">Internal Document (เอกสารภายใน)</span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              <span className="fw-bold">Request System (ระบบงานร้องขอ)</span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>

          <tr>
            <td>
              {' '}
              <span className="fw-bold">Welfare (สวัสดิการพนักงาน)</span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม</p>
              <p className="mb-0">- ฐานหน้าที่ตามกฎหมาย&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                {' '}
                <span className="fw-bold">Payroll (การทำเงินเดือน)</span>
              </p>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
            </td>
          </tr>
          <tr>
            <td>
              <span className="fw-bold">
                Entry Record (บันทึกการเข้าออกงาน SPD37)
              </span>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT</p>
              <p className="mb-0">- ข้อมูลชีวมิติ&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานความยินยอม</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Finance (การทำธุรกรรมทางการเงิน)</p>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">
                Support APPS Project(การบริการเพื่อสนับสนุนผู้ใช้งาน APPS)
              </p>
            </td>
            <td>
              <p className="mb-0">- ชื่อ-นามสกุล</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูล IT&nbsp;</p>
            </td>
            <td>- ฐานสัญญา</td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Verification (การยืนยันตัวตน)</p>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานหน้าที่ตามกฎหมาย&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Data Report (รายงานเอกสาร)</td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานหน้าที่ตามกฎหมาย</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="fw-bold">Collect Data (การจัดเก็บข้อมูล)</p>
            </td>
            <td>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- เอกสารหลักฐาน&nbsp;</p>
            </td>
            <td>- ฐานหน้าที่ตามกฎหมาย</td>
          </tr>
          <tr>
            <td className="fw-bold">Tracking location (บริการระบบติดตาม)</td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์&nbsp;</p>
              <p className="mb-0">- ข้อมูลที่อยู่และที่ติดต่อ&nbsp;</p>
              <p className="mb-0">- ข้อมูลประวัติ&nbsp;</p>
              <p className="mb-0">- ข้อมูล ID&nbsp;</p>
              <p className="mb-0">- ข้อมูล IT&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน&nbsp;</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน&nbsp;&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานสัญญา</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">Referral Program (SPD3)</td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการเงิน&nbsp;</p>
              <p className="mb-0">- ข้อมูลประวัติ</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">การเข้าออกพื้นที่ (SPD39)</td>
            <td>
              <p className="mb-0">- ข้อมูลอัตลักษณ์</p>
              <p className="mb-0">- ข้อมูลเกี่ยวกับการทำงาน</p>
              <p className="mb-0">- ข้อมูล ID</p>
              <p className="mb-0">- ข้อมูล IT</p>
              <p className="mb-0">- ข้อมูลชีวมิติ&nbsp;</p>
            </td>
            <td>
              <p className="mb-0">- ฐานความยินยอม</p>
              <p className="mb-0">- ฐานประโยชน์อันชอบธรรม&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </Table>
      <p className="mb-0">
        {spas}
        บริษัทจะประมวลผลข้อมูลส่วนบุคคลของตามวัตถุประสงค์ที่ได้แจ้งไว้เท่านั้น
        ในบางกรณีอาจพิจารณาว่าสามารถประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุผลอื่นที่เกี่ยวข้องและไม่ขัด
        หรือนอกเหนือไปจากวัตถุประสงค์เดิม
        แต่ในกรณีที่บริษัทจำเป็นต้องประมวลผลข้อมูลด้วยวัตถุประสงค์อื่นที่ไม่เกี่ยวข้องกับวัตถุประสงค์เดิม
        บริษัทจะขอความยินยอมใหม่เพื่อการใช้ข้อมูลตามวัตถุประสงค์ที่ทำการขอเข้ามาตามกระบวนการอีกครั้ง
      </p>
      <p className="mb-0">
        {spas}บริษัท
        อาจมีความจำเป็นต้องส่งข้อมูลไปยังหน่วยงานภายนอกดังต่อไปนี้เพื่อประมวลผลข้อมูลตามหน้าที่ความรับผิดชอบตามสัญญาหรือตามกฎหมาย
        เช่น
      </p>
      <p className="mb-0">{spas}- สถานพยาบาล&nbsp;&nbsp;&nbsp;</p>
      <p className="mb-0"> {spas}- หน่วยงานฉุกเฉินที่เกี่ยวข้อง</p>
      <p className="mb-0"> {spas}- ธนาคาร&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p className="mb-0"> {spas}- บริษัทในเครือ&nbsp;</p>
      <p>
        {spas}ในกรณีที่ใช้หรือส่งข้อมูลส่วนบุคคลไปยังหน่วยงานภายนอก
        บริษัทจะดำเนินการเท่าที่จำเป็นโดยใช้หรือส่งข้อมูลให้น้อยที่สุด
        และอาจพิจารณาใช้วิธีจัดทำข้อมูลนิรนาม (Data Anonymization) การแฝงข้อมูล
        (Pseudonymous Data) เพื่อความปลอดภัยของข้อมูล
        โดยกรณีบุคคลภายนอกที่เป็นผู้ประมวลผลข้อมูลส่วนบุคคลให้กับบริษัท
        จะต้องจัดให้มีมาตรการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสมตามนโยบายนี้
        และบริษัทจะไม่อนุญาตให้บุคคลภายนอกดังกล่าวใช้ข้อมูลเพื่อวัตถุประสงค์อื่นนอกจากที่บริษัทกำหนด&nbsp;
      </p>
      <h5>๗. สิทธิ์ของเจ้าของข้อมูลส่วนบุคคล</h5>
      <p className="mb-0">
        {spas}๗.๑
        สิทธิ์ในการได้รับแจ้งรายละเอียดเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล
        (Right to be Informed)
      </p>
      <p className="mb-0">
        {' '}
        {spas}๗.๒ สิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคล (Right of Access)&nbsp;
      </p>
      <p className="mb-0">
        {' '}
        {spas}๗.๓ สิทธิ์ในการโอนข้อมูลส่วนบุคคล (Right to Data
        Portability)&nbsp;
      </p>
      <p className="mb-0">
        {spas}๗.๔ สิทธิ์ในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (Right to
        Object)&nbsp;
      </p>
      <p className="mb-0">
        {' '}
        {spas}๗.๕ สิทธิ์ในการลบข้อมูลส่วนบุคคล (Right to Erasure)&nbsp;
      </p>
      <p className="mb-0">
        {spas}๗.๖ สิทธิ์ในการระงับการใช้ข้อมูลส่วนบุคคล (Right to Restrict
        Processing)&nbsp;
      </p>
      <p className="mb-0">
        {' '}
        {spas}๗.๗ สิทธิ์ในการแก้ไขข้อมูลส่วนบุคคล (Right to Rectification)&nbsp;
      </p>
      <p className="mb-0">
        {spas}ในบางกรณีตามสภาพของการดำเนินการ
        บริษัทอาจไม่สามารถดำเนินการได้ตามที่ท่านขอได้ เช่น
        มีความจำเป็นต้องดำเนินการตามหน้าที่ตามสัญญาหรือกฎหมาย เป็นต้น
        อย่างไรก็ดีในกรณีที่ท่านได้ให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคลเอาไว้
        ท่านสามารถถอนความยินยอมนั้นเมื่อใดก็ได้ด้วยการติดต่อไปยังส่วนงานที่เกี่ยวข้อง
        โดยบริษัทจะยุติการประมวลผลข้อมูลดังกล่าวโดยเร็วที่สุด
        แต่การถอนความยินยอมนั้นจะไม่มีผลเป็นการยกเลิกเพิกถอนการประมวลผลข้อมูลส่วนบุคคลที่ได้ดำเนินการไปแล้ว{' '}
      </p>
      <p>
        {spas}ขอให้ท่านรับทราบว่าบริษัทจะบันทึกรายการต่าง ๆ
        ที่ได้ดำเนินการเกี่ยวกับคำร้องของท่านเอาไว้เพื่อใช้ในการแก้ไขปัญหาต่าง
        ๆ ที่เกิดขึ้น
      </p>
      <h5>๘. การเพิกถอนความยินยอม</h5>
      <p className="mb-0">
        {spas}
        เจ้าของข้อมูลสามารถเพิกถอนความยินยอมที่ได้ให้ไว้กับบริษัทในการเก็บรวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลดังกล่าวข้างต้นเสียเมื่อใดก็ได้
        ด้วยการแจ้งความประสงค์ให้บริษัททราบพร้อมทั้งเหตุผลแห่งการนั้น
        โดยบริษัทจะดำเนินการตามที่ได้รับแจ้งเว้นแต่กรณีมีข้อจำกัดสิทธิ์ในการถอนความยินยอมโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่
        เจ้าของข้อมูล
      </p>
      <p>
        {spas}ทั้งนี้ การเพิกถอนความยินยอมของเจ้าของข้อมูลส่วนบุคคล
        จะไม่ส่งผลกระทบต่อการเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
        ที่เจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมไปแล้วก่อนหน้านั้น
      </p>
      <h5>๙. การปฏิเสธและการจัดทำบันทึก</h5>
      <p className="mb-0">
        {spas}ในกรณีที่เจ้าของข้อมูลร้องขอให้บริษัทดำเนินการตามที่ระบุไว้ในข้อ
        ๗.๑ ๗.๘ หรือ ข้อ ๘
        บริษัทจะดำเนินการตามที่ได้รับคำร้องขอภายในเวลาที่เหมาะสมและตามขั้นตอนการปฏิบัติของบริษัท
        เว้นแต่ในกรณีดังนี้ บริษัทอาจปฏิเสธการดำเนินการดังกล่าวได้
      </p>
      <p className="mb-0"> {spas}๙.๑ โดยสภาพแล้วไม่อาจดำเนินการดังกล่าวได้</p>
      <p className="mb-0">
        {spas}๙.๒ เป็นการปฏิเสธตามกฎหมายหรือตามคำสั่งศาล
        และการเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลนั้นจะส่งผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิ์และเสรีภาพบุคคลอื่น
      </p>
      <p className="mb-0">
        {spas}๙.๓
        การส่งหรือการโอนข้อมูลส่วนบุคคลนั้นเป็นการปฏิบัติหน้าที่เพื่อประโยชน์สาธารณะหรือเป็นการปฏิบัติหน้าที่ตามกฎหมาย
        หรือการใช้สิทธิ์ดังกล่าวอาจเป็นการละเมิดสิทธิ์หรือเสรีภาพของบุคคลอื่น
      </p>
      <p className="mb-0">
        {spas}๙.๔ การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้น
        ผู้ควบคุมข้อมูลส่วนบุคคลได้แสดงให้เห็นถึงเหตุอันชอบด้วยกฎหมายที่สำคัญยิ่งกว่า
      </p>
      <p>
        {spas}๙.๕ การเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลนั้นเป็นไปเพื่อก่อตั้งสิทธิ์เรียกร้องตามกฎหมายการปฏิบัติตามหรือการใช้สิทธิ์เรียกร้องตามกฎหมาย
        หรือการยกขึ้นต่อสู้สิทธิ์เรียกร้องตามกฎหมายในกรณีที่บริษัทปฏิเสธไม่ดำเนินการตามคำร้องขอของเจ้าของข้อมูลให้ดำเนินการอย่างใดอย่างหนึ่งตามข้อ
        ๗.๑ ๗.๘ หรือ ข้อ ๘ นั้น
        บริษัทจะจัดทำรายงานการบันทึกการปฏิเสธพร้อมเหตุผลแห่งการปฏิเสธจัดเก็บไว้ในฝ่าย
        แผนก ภายในบริษัทซึ่งปฏิเสธคำร้องขอของเจ้าของข้อมูล
      </p>
      <h5>๑๐. มาตรการรักษาความมั่นคงปลอดภัยและคุณภาพของข้อมูล</h5>
      <p>
        {spas}
        บริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน
        บริษัทจึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
        การเข้าถึง ทำลาย ใช้ แปลง
        แก้ไขหรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
        ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาต
        นอกจากนี้คณะกรรมการของบริษัทยังได้จัดทำนโยบาย ระเบียบปฏิบัติ คู่มือ
        แนวทางและอบรมให้ความรู้ ในการเก็บรักษา ใช้ และเปิดเผยข้อมูลส่วนบุคคล
        เพื่อให้พนักงานของบริษัทในทุกระดับชั้นดำเนินการเก็บรักษา ใช้ และ
        เปิดเผยข้อมูลส่วนบุคคลปฏิบัติตามมาตราฐานของบริษัทและสอดคล้องกับกฎหมายที่เกี่ยวข้อง
      </p>
      <h5>๑๑. เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h5>
      <p>
        {spas}{' '}
        บริษัทได้มีการดำเนินการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        พ.ศ. ๒๕๖๒ โดยแต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection
        Officer : DPO)
        เพื่อตรวจสอบการดำเนินการของบริษัทที่เกี่ยวกับการเก็บรวบรวม
        ใช้และเปิดเผยข้อมูลส่วนบุคคลให้สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        พ.ศ. ๒๕๖๒ รวมถึงกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคล
        นอกจากนี้ บริษัทได้จัดทำระเบียบ
        คำสั่งให้ผู้เกี่ยวข้องดำเนินการตามที่กำหนดไว้
        เพื่อให้การดำเนินงานตามแนวนโยบายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลให้เป็นไปด้วยความเรียบร้อย
        อีกทั้ง
        ยังเป็นไปตามนโยบายของคณะกรรมการกำกับการบริหารข้อมูลส่วนบุคคลและความมั่นคงปลอดภัยไซเบอร์ของบริษัทที่กำหนด
      </p>
      <h5>๑๒. ระยะเวลาการเก็บและการทำลายข้อมูลส่วนบุคคล</h5>
      <p>
        {spas}
        บริษัทจะจัดเก็บรักษาข้อมูลตามที่ได้รับจากเจ้าของข้อมูลตามระยะเวลาเท่าที่จำเป็นในการดำเนินการตามวัตถุประสงค์ดังที่ได้กล่าวมาข้างต้นเท่านั้น
        เว้นแต่ในกรณีที่มีกฎหมายกำหนดไว้โดยเฉพาะให้เก็บไว้ได้นานกว่านั้น หรือ
        เป็นการจำเป็นเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
        การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
        หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องตามกฎหมาย
        หรือเพื่อดำเนินการตามสัญญาซึ่งบริษัทมีสิทธิตามกฎหมายในการเก็บรักษาข้อมูลส่วนบุคคลทางบริษัทได้ทำนโยบายการเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
        (Sky ICT Data Retention Policy)
        เพื่อเป็นการระบุถึงระยะเวลาในการเก็บรักษาข้อมูล&nbsp;
      </p>
      <h5>๑๓. ช่องทางการติดต่อบริษัท&nbsp;</h5>
      <Table bordered responsive>
        <thead>
          <tr>
            <th className="tr-header">
              รายละเอียดการติดต่อผู้ควบคุมข้อมูล/ผู้ประมวลผลข้อมูลส่วนบุคคล
            </th>
            <th className="tr-header">
              รายละเอียดการติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className="mb-0">
                บริษัท สกาย ไอซีที จํากัด (มหาชน) และบริษัทในเครือ
              </p>
              <p className="mb-0">
                เลขที่ 55 อาคาร เอ.เอ.แคปปิตอล รัชดา ชั้น 5-7 ถนนรัชดาภิเษก
              </p>
              <p className="mb-0">แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400</p>
              <p className="mb-0">โทรศัพท์: 0 2029 7888</p>
              <p className="mb-0">อีเมล: info@skyict.co.th</p>
            </td>
            <td>
              <p>อีเมล: DPO@skyict.co.th</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default PolicyTHPage
