import React from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap'

function FooterLayout() {
  return (
    <Container className="">
      <Row className='mt-5'>
        <Col lg={12}>
          <p className="text-center small">
            Copyright {new Date().getFullYear()} SKY ICT PCL., All rights
            reserved. V.1.0.0
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default FooterLayout
