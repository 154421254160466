import { Translate } from "../config"

export const GET_TRANSLATE = 'GET_TRANSLATE'

export const updateTranslate = (translate) => {
  return {
    type: GET_TRANSLATE,
    payload: {
      translate
    }
  }
}

export const restoreTranslate = () => {
  const translate = localStorage.getItem(Translate)

  return {
    type: GET_TRANSLATE,
    payload: {
      translate
    }
  }
}
