import { GET_TRANSLATE } from '../action/translate.action'

const initState = {
  translate: ''
}

const translateReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TRANSLATE:
      return {
        ...state,
        translate: action.payload.translate
      }
    default:
      return state
  }
}

export default translateReducer
