import { Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Container, Dropdown, Image, Navbar } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import Logo from '../Logo.png'
import { TfiWorld } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { store } from '..'
import { CODE_ENG, CODE_TH, Translate } from '../config'
import { useDispatch } from 'react-redux'
import { updateTranslate } from '../action/translate.action'
import FooterLayout from './footer'
function HeaderLayouts() {
  const trans = store.getState().translateReducer.translate

  const [placement, setPlacement] = useState(CODE_TH)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const placementChange = (e) => {
    setPlacement(e.target.value)

    localStorage.setItem(Translate, e.target.value)

    dispatch(updateTranslate(e.target.value))

    navigate('policy/' + e.target.value)
  }
  useEffect(() => {
    setPlacement(trans)
  }, [trans])

  return (
    <>
      <Navbar fixed="top" bg="nav">
        <Container>
          <Navbar.Brand>
            <Image src={Logo} width={125} fluid />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <TfiWorld className="me-2" />

              <Radio.Group value={placement} onChange={placementChange}>
                <Radio.Button value={CODE_TH}>ภาษาไทย</Radio.Button>
                <Radio.Button value={CODE_ENG}>English</Radio.Button>
              </Radio.Group>
              {/* <span className="text-primary">TH</span> */}
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container style={{ marginTop: 130 }}>
        <Outlet />
      </Container>
      <FooterLayout />
    </>
  )
}

export default HeaderLayouts
