import './App.css'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { MENU_PVC, MENU_PVC_ENG } from './utils/routes'
import PolicyTHPage from './pages/policy-th'
import HeaderLayouts from './layouts/header'
import PolicyEngPage from './pages/policy-eng'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import * as translateActions from './action/translate.action'
const Pagedefault = MENU_PVC

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(translateActions.restoreTranslate())

    return () => {
      dispatch(translateActions.restoreTranslate())
    }
  }, [dispatch])

  return (
    <Routes>
      <Route path="/" element={<HeaderLayouts />}>
        <Route path="/" element={<Navigate to={Pagedefault} />} />
        <Route path={MENU_PVC} element={<PolicyTHPage />} />
        <Route path={MENU_PVC_ENG} element={<PolicyEngPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

const NotFound = () => (
  <div className="container">
    <div className="row">
      <div
        className="col-lg-6 mx-auto my-auto text-center align-content-center d-grid"
        style={{ height: '100vh' }}
      >
        <h1>404 - Not Found!</h1>
        <p>
          We're sorry, the page you requested could not be found Please go back
          to the homepage
        </p>
        <Link to={Pagedefault} className="btn btn-outline-secondary rounded-3 ">
          Go Home
        </Link>
      </div>
    </div>
  </div>
)

export default App
